import { getCurrentUser } from "vuefire";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const user = await getCurrentUser();

  if (!user) {
    // If the path starts with /students navigate to /students/auth/login

    if (
      useRuntimeConfig().public.oauthClient.enableRedirect == true &&
      useRuntimeConfig().public.oauthClient.oauthAuthUrl != undefined
    ) {
      return (window.location.href =
        useRuntimeConfig().public.oauthClient.oauthAuthUrl);
    } else {
      return navigateTo({
        path: "/home",
        // This allos me to store the path the user was trying to access so that once they log in, I can redirect them back to where they were trying to access
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }

  const claims = await user.getIdTokenResult(true);

  const idToken = await user.getIdToken();

  const idTokenCookie = useCookie("authToken", {
    secure: true,
  });

  idTokenCookie.value = idToken;

  if (
    claims.claims.isAdmin != true &&
    claims.claims.isStudentAccount &&
    window.location.host.includes("students") != true
  ) {
    const newUrl = `${window.location.protocol}//students.${window.location.host}/`;
    window.location.href = newUrl;
  }
});
